<template>
  <div>
    <b-tabs class="shadow p-2">
      <b-overlay
        :show="$apollo.loading"
        spinner-variant="primary"
      >
        <b-card
          v-if="programs.length < 1"
          class="text-center"
        >
          No programs found under your organizations!
        </b-card>
        <div
          v-if="programs.length > 1"
          class="d-flex  align-items-center justify-content-between  "
        >
          <div>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              @click="$router.back()"
            >
              <feather-icon
                class="mr-25"
                icon="ChevronLeftIcon"
              />
            </b-button>
          </div>
          <div>
            <button
              :class="`btn btn-cart rounded ${view ? 'btn-primary': 'btn-outline-primary'}`"
              type="button"
              @click="updateView(true)"
            >
              Grid
            </button>
            <button
              :class="`btn btn-cart rounded ml-1 ${!view ? 'btn-primary': 'btn-outline-primary'}`"
              type="button"
              @click="updateView(false)"
            >
              List
            </button>
          </div>
        </div>
        <section
          v-if="!view"
          class="mt-2"
        >
          <b-card class="">
            <vue-good-table
              :columns="columns"
              :rows="programs"
              :pagination-options="pagination"
            >
              <template
                slot="table-row"
                slot-scope="props"
              >
                <!-- <span v-if="props.column.field === 'organization'">
              {{ props.row.users_organizationtable.title }}
            </span> -->
                <span v-if="props.column.field === 'title'">
                  <b-badge variant="primary">
                    {{ props.row.originalIndex+1 }}
                  </b-badge>
                  {{ props.row.title }}<br>
                  <b-badge
                    v-if="uservisibility"
                    v-b-tooltip="props.row.users_organizationtable.title"
                    variant="light-primary"
                    style="max-width: 27ch; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                  >
                    {{ props.row.users_organizationtable.title }}
                  </b-badge>
                </span>
                <span v-else-if="props.column.field === 'type'">
                  {{ props.row.type }}

                </span>
                <span v-else-if="props.column.field === 'date'">
                  {{ formatDate(props.row.begin_date) }}
                  <span> - </span>
                  {{ formatDate(props.row.end_date) }}
                </span>

                <!-- Column: Action -->
                <span
                  v-else-if="props.column.field === 'action'"
                  class="d-flex"
                >
                  <b-button
                    class="mr-50"
                    size="sm"
                    variant="outline-primary"
                    :to="{name: 'events-application-manager', params: {id: props.row.id}}"
                  >
                    View Details
                  </b-button>
                  <b-button
                    v-if="props.row.type == 'Custom'"
                    class="mr-50"
                    size="sm"
                    variant="outline-primary"
                    :to="{name: 'events-program-manager', params: {id: props.row.id}}"
                  >
                    Manager
                  </b-button>
                  <b-button
                    v-if="props.row.type != 'Custom'"
                    v-b-tooltip="'Update Links'"
                    class="mr-50"
                    size="sm"
                    variant="outline-primary"
                    @click="selectedRow = props.row.originalIndex; $bvModal.show('updateLinks')"
                  >
                    <feather-icon
                      icon="VideoIcon"
                      size="14"
                    />
                  </b-button>
                  <b-button
                    v-if="props.row.type != 'Custom'"
                    v-b-tooltip="'Update Links'"
                    class="mr-50"
                    size="sm"
                    variant="outline-primary"
                    @click="selectedRow = props.row.originalIndex; $bvModal.show('updateLinks')"
                  >
                    <feather-icon
                      icon="PlayCircleIcon"
                      size="14"
                    />
                  </b-button>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap ">
                      Showing 1 to
                    </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['3','5','10']"
                      class="mx-1"
                      @input="(value)=>props.perPageChanged({currentPerPage:value})"
                    />
                    <span class="text-nowrap"> of {{ props.total }} entries </span>
                  </div>
                  <div>
                    <b-pagination
                      :per-page="pageLength"
                      :total-rows="props.total"
                      :value="1"
                      class="mt-1 mb-0"
                      first-number
                      last-number
                      next-class="next-item"
                      prev-class="prev-item"
                      @input="(value)=>props.pageChanged({currentPage:value})"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-card>
        </section>
        <section
          v-if="view"
          class="grid-view"
        >
          <b-card
            v-for="(program, j) in programs"
            :key="j"
            class="ecommerce-card"
            header-tag="header"
            tag="article"
          >
            <b-img
              class="rounded"
              src="https://picsum.photos/600/300/?image=25"
              alt="image"
              img-top
              style="width: 100%; position: relative;"
            />
            <b-badge
              style="position: absolute; top: 95px; right: 25px;color: black;"
              variant="light"
            >
              Event Type
            </b-badge>
            <b-badge
              style="position: absolute; top: 125px; right: 25px;color: black;"
              variant="light"
            >
              Free
            </b-badge>
            <b-badge
              style="position: absolute; top: 95px; left: 25px;color: black;"
              variant="light"
            >
              E
            </b-badge>
            <template #header>
              <div
                v-if="uservisibility"
                class="d-flex align-items-center"
              >
                <b-img
                  rounded
                  alt="Rounded image"
                  src="https://shorturl.at/kxFUX"
                  style="height: 30px; width: 30px; margin-right: 10px;"
                />
                <b-card-text> <strong>{{ program.users_organizationtable.title }}</strong></b-card-text>
              </div>
            </template>
            <div class="mt-2 mb-1">
              <div>
                <b-card-text style="font-size: large; margin-top: 15px; margin-bottom: 5px;">
                  <strong> {{ program.title }} </strong>
                </b-card-text>
              </div>
              <div class="d-flex align-items-baseline   justify-content-between">
                <b-card-text><strong>Date : {{ beginDate(program.begin_date) }}</strong></b-card-text>
                <b-badge class="badge badge-light-success">
                  Online
                </b-badge>
              </div>
              <b-card-sub-title class="mt-1">
                Some quick example text to build on the card title and make up the bulk of the card's content.
              </b-card-sub-title>
            </div>
            <div class="item-options text-center d-flex justify-content-between ">
              <b-button-group>
                <b-link
                  size="sm"
                  :to="{ name: 'events-application-manager', params: { id: program.id } }"
                  class="btn btn-outline-primary btn-cart"
                >
                  <span>View Details</span>
                </b-link>
              </b-button-group>
              <b-button-group>
                <b-link
                  v-if="program.type == 'Custom'"
                  size="sm"
                  :to="{name: 'events-program-manager', params: {id: program.id}}"
                  class="btn btn-primary btn-cart"
                >
                  <span>Manage</span>
                  <!-- <feather-icon
                        icon="SettingsIcon"
                        size="14"
                      /> -->
                </b-link>
                <b-link
                  v-if="program.type != 'Custom'"
                  v-b-tooltip="'Join'"
                  class="btn btn-outline-primary btn-cart ml-1 "
                  size="sm"
                  variant="outline-primary"
                  @click="selectedRow = program.originalIndex; $bvModal.show('updateLinks')"
                >
                  <feather-icon
                    icon="LinkIcon"
                    size="14"
                  />
                </b-link>
                <b-link
                  v-if="program.type != 'Custom'"
                  v-b-tooltip="'Record'"
                  class="btn btn-outline-primary btn-cart"
                  size="sm"
                  variant="outline-primary"
                  @click="selectedRow = program.originalIndex; $bvModal.show('updateLinks')"
                >
                  <feather-icon
                    icon="VideoIcon"
                    size="14"
                  />
                </b-link>
              </b-button-group>
            </div>
          </b-card>
        </section>
      </b-overlay>
    </b-tabs>
    <b-modal
      id="view-event-modal"
      title="Event Title"
      hide-footer
      size="lg"
    >
      <b-img
        src="https://picsum.photos/600/300/?image=25"
        style="width: 100%;"
      />
      <b-table
        stacked
        bordered
        :items="items"
      >
        <template #cell(url)="data">
          <b-link
            :href="prependHttps(data.item.url)"
            target="_blank"
          >{{ data.item.url }}
          </b-link>
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  // BCardBody,
  BCardSubTitle,
  BButtonGroup,
  BCardText,
  // BCardTitle,
  BLink, BOverlay,
  BTabs,
  // BCol,
  BBadge, BImg, BModal, BTable,
} from 'bootstrap-vue'
import gql from 'graphql-tag'
import { VueGoodTable } from 'vue-good-table'
import { getUserData } from '@/utils/auth'
import { formatDate } from '@/@core/utils/utils'

export default {
  components: {
    BCard,
    BCardText,
    BTabs,
    // BCardBody,
    VueGoodTable,
    // BCardTitle,
    BModal,
    BCardSubTitle,
    BOverlay,
    BTable,
    BLink,
    BButtonGroup,
    BBadge,
    // BCol,
    BImg,
  },
  data() {
    return {
      programs: [],
      rows: [],
      columns: [
        {
          label: 'Event Title',
          field: 'title',
        },
        {
          label: 'Event Type',
          field: 'type',
        },
        // {
        //   label: 'Organization',
        //   field: 'organization',
        // },
        {
          label: 'Date',
          field: 'date',
        },
        // {
        //   label: 'Mode',
        //   field: 'mode',
        // },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      view: true,
      uservisibility: !this.getIncubatorFromSubdomain(),
      items: [
        {
          'Organized By': 'xyz', 'Event Title': 'Startup India', 'Event Type': 'Online', 'Description ': 'this is a description this is a description this is a description this is a description this is a description this is a description this is a description', 'Start Date & Time': '12 sept 2023', 'End Date & Time': '24 sept 2023',
        },
      ],
      userOrgs: getUserData()
        .associatedOrgDetails

        .map(el => el.organization_id)
        .filter(n => n),
    }
  },
  methods: {
    formatDate,
    beginDate(date) {
      const dateNew = new Date(date)
      const options = { day: 'numeric', month: 'long', year: 'numeric' }
      const formattedDate = dateNew.toLocaleDateString('en-US', options)
      return formattedDate
    },
    updateView(view) {
      this.view = view
    },
  },
  apollo: {
    programs: {
      query() {
        const subdomain = this.getIncubatorFromSubdomain() || '.*'
        return gql`
          {
          programs_basicinfo(where: {users_organizationtable: {domain: {_regex: "${subdomain}"}}, is_event: {_eq: true}, programs_startupparticipants: {programs_applicantstable: {organization_id_id: {_in: [${this.userOrgs}]}}}}) {
            id
            title
            begin_date
            end_date
            capacity
            industry
            fund_raising_assistance
            description
            status
            type
            users_organizationtable {
              logo
              title
              type
            }
          }
        }`
      },
      update: data => data.programs_basicinfo,
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
